import { DownloadOutlined, FilterAltOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import { saveAttStatus } from "lib/Reducer/attendanceSlice";
import { useState } from "react";
import { IoLogInSharp, IoLogOutSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

const AttendanceHeader = ({
  setOpenSidebar,
  allAttendence,
  handleCheckOut,
  setSearch,
  search,
  setPagination,
  pagination,
  setOpenFilter,
  isFiltering,
  permissions,
  filters,
}) => {
  const { loading, fetchData } = useApi();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);
  const attStatus = useSelector((state) => state?.attendance?.status); // 1==="Not checked In", 2==="Checked In", 3==="Checked Out"
  const dispatch = useDispatch();

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const handleCheckoutBtn = () => {
    handleCheckOut(
      allAttendence.find(
        (att) =>
          new Date(att.date).toLocaleDateString() ===
          new Date().toLocaleDateString()
      )?.id
    );
    dispatch(saveAttStatus({ status: 3, attendance_id: null }));
  };

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/attendance/list/export",
        data: {
          type: "xlsx",
          trash_export: false,
          search,
          ...filters,
        },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        {/* {permissions?.isAdmin === 1 && ( */}
          <Button
            variant={isFiltering() ? "solid" : "outlined"}
            startIcon={<FilterAltOutlined />}
            onClick={() => setOpenFilter(true)}
          >
            Filters
          </Button>
        {/*  )} */}

        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={handleExport}
          disabled={!permissions.export}
        >
          Export
        </LoadingButton>

        {permissions?.isAdmin === 1 ? (
          <Button
            variant="outlined"
            startIcon={<IoLogInSharp />}
            onClick={() => setOpenSidebar(true)}
          >
            Create Attendance
          </Button>
        ) : attStatus === 1 ? (
          <Button
            disabled={!permissions?.checkin}
            variant="outlined"
            startIcon={<IoLogInSharp />}
            onClick={() => setOpenSidebar(true)}
          >
            Create Attendance
          </Button>
        ) : attStatus === 2 ? (
          <Button
            disabled={!permissions?.checkout}
            variant="outlined"
            startIcon={<IoLogOutSharp />}
            onClick={handleCheckoutBtn}
          >
            Check Out
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
};

export default AttendanceHeader;
